import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    booking: [],
    payment: [],
    rideTakenData: [],
    oderDetails: [],
  },
  reducers: {
    setUser: (state, action) => {
      state.users = action.payload;
      localStorage.setItem("myToken", state.users.token);
    },
    setBooking: (state, action) => {
      state.booking = action.payload;
    },
    setRideTaken: (state, action) => {
      state.rideTakenData = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.oderDetails = action.payload;
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
    },
  },
});

export const {
  setUser,
  setBooking,
  setPayment,
  setRideTaken,
  setOrderDetails,
} = userSlice.actions;
export default userSlice.reducer;
