import React from "react";
import { Navigate } from "react-router-dom";
const useAuth = () => {
  const user = localStorage.getItem("myToken");
  if (user) {
    return true;
  } else {
    return false;
  }
};
function PrivateRoute({ children }) {
  const authed = useAuth();

  return authed ? children : <Navigate to={"/"} />;
}

export default PrivateRoute;
