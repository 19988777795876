const initialState = { swiperInstance: {} };
const swiperInstanceReducer = (state = initialState, { type }) => {
  if (type == "SET_SWIPER_INSTANCE") {
    return {
      ...state,
      swiperInstance: {},
    };
  } else {
    return state;
  }
};
export default swiperInstanceReducer;
