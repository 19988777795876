import { createSlice } from "@reduxjs/toolkit";

const ReserveBookingSlice = createSlice({
  name: "Reserve Booking",
  initialState: {
    verificationDetails: [],
    booking: [],
    payment: [],
    color: "Electric Red",
  },
  reducers: {
    setVerificationDetails: (state, action) => {
      state.verificationDetails = action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setBooking: (state, action) => {
      state.booking = action.payload;
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
    },
  },
});

export const { setVerificationDetails, setBooking, setPayment, setColor } =
  ReserveBookingSlice.actions;
export default ReserveBookingSlice.reducer;
